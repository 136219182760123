<template>
  <div class="app-container">
    <div class="content">
      <div class="detail">
        <div class="box">
          <span class="label">客户名称：</span>
          <div class="text">{{ cname }}</div>
        </div>
        <div class="box">
          <span class="label">门店名称：</span>
          <div class="text">{{ shopName }}</div>
        </div>
        <div class="box">
          <span class="label">端口号：</span>
          <div class="text">{{ boxPort }}</div>
        </div>
        <div class="box">
          <span class="label">盒子Mac-ID：</span>
          <div class="text">{{ boxMacId }}</div>
        </div>
        <div class="box">
          <span class="label">状态：</span>
          <div class="text">{{ status }}</div>
        </div>
      </div>
      <div class="title">
        <span>{{ agentno }}</span
        >-已绑定摄像头
      </div>
      <el-table :data="bindTableData" v-loading="bindLoading" class="bindtable" row-key="pkid">
        <el-table-column type="index" align="right" :index="indexTable" min-width="30" label="序号"> </el-table-column>
        <el-table-column prop="camerid" align="center" label="CameraID" min-width="150"> </el-table-column>
        <el-table-column prop="cameraNo" align="left" label="摄像头名称" width="130"> </el-table-column>
        <el-table-column prop="cameraType" align="left" show-overflow-tooltip label="摄像头类型" :formatter="formatter">
        </el-table-column>
        <el-table-column prop="vendor" align="left" show-overflow-tooltip label="摄像头品牌"> </el-table-column>
        <el-table-column prop="cameraModel" align="left" show-overflow-tooltip label="摄像头型号"> 
          <template slot-scope="scope">
              {{ scope.row.cameraModel || '--' }}
            </template>
        </el-table-column>
        <el-table-column prop="macid" align="left" label="MAC-ID"> </el-table-column>
        <el-table-column prop="ip" align="left" label="IP地址"> </el-table-column>
        <el-table-column prop="bindTime" align="left" show-overflow-tooltip label="绑定时间"> </el-table-column>
        <el-table-column prop="imageUrl" align="left" label="截图">
          <template slot-scope="scope">
            <img :src="scope.row.imageUrl" @click="enlargeImg(scope.row.imageUrl)" class="screenshot" />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="linklive(scope.row)" type="text" size="small">直播</el-button>
            <el-button @click="unbind(scope.row)" class="unbind" type="text" size="small">解绑</el-button>
            <el-button @click="change(scope.row)" class="unbind" type="text" size="small">更换</el-button>
            <el-button type="text" size="small" class="unbind" @click="toDeviceInfo(scope.row)">设备信息查询</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-button class="newDevice" type="primary" @click="getCameraList(true)">扫描新的设备</el-button>
      <div class="title">请选择摄像头进行绑定</div>
      <el-input
        v-model.trim="wordString"
        class="word-string"
        placeholder="请输入摄像头MacID、绑定盒子MacID检索"
      ></el-input>
      <el-table
        :data="nobindtable"
        row-key="pkid"
        class="nobindtable"
        v-loading="unbindLoading"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        :header-cell-class-name="tableClass"
      >
        <el-table-column type="selection" :selectable="selectInit" width="55" :disable="true"> </el-table-column>
        <el-table-column type="index" align="right" show-overflow-tooltip label="序号"> </el-table-column>
        <el-table-column prop="macid" align="right" show-overflow-tooltip label="摄像头MacID"> </el-table-column>
        <el-table-column
          prop="cameraType"
          align="right"
          show-overflow-tooltip
          label="摄像头类型"
          :formatter="formatter"
        >
        </el-table-column>
        <el-table-column prop="vendor" align="right" show-overflow-tooltip label="摄像头品牌"> </el-table-column>
        <el-table-column prop="cameraModel" align="right" show-overflow-tooltip label="摄像头型号"> 
          <template slot-scope="scope">
              {{ scope.row.cameraModel || '--' }}
            </template>
        </el-table-column>
        <el-table-column prop="ip" align="right" show-overflow-tooltip label="IP地址"> </el-table-column>
        <el-table-column prop="bindStatus" align="center" label="绑定状态" width="130">
          <template slot-scope="scope">
            <span type="text" size="small">{{ scope.row.bindStatus ? '已绑定' : '未绑定' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="boxMAC" label="绑定盒子MacID">
          <template slot-scope="scope">
            {{ scope.row.boxMAC || '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="imageUrl" align="center" label="截图">
          <template slot-scope="scope">
            <img :src="scope.row.imageUrl" @click="enlargeImg(scope.row.imageUrl)" class="screenshot" />
          </template>
        </el-table-column>
      </el-table>
      <div class="allSelect">
        <div>
          <el-checkbox
            v-model="select"
            :indeterminate="isIndeterminate"
            @change="toggleSelection(nobindtable)"
            v-show="nobindtable.length"
            >全选</el-checkbox
          >
          <el-button @click="bindBox" v-show="nobindtable.length">绑定</el-button>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-button @click="back">上一步</el-button>
      <el-button type="primary" @click="next">完成</el-button>
      <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" :before-close="handleClose">
        <img :src="bigImgUrl" class="bigImgUrl" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { upgrade } from 'src/api/legacy'

export default {
  data() {
    return {
      id: '',
      cname: '',
      shopName: '',
      boxPort: '',
      boxMacId: '',
      agentno: '',
      bindTableData: [],
      newTable: [],
      multipleSelection: [],
      pkid: '',
      status: '',
      select: false,
      imgDialog: false,
      bigImgUrl: '',
      currentPage: 1,
      pageSize: 5,
      total: 0,
      wordString: '',
      isIndeterminate: false,
      bindLoading: false,
      unbindLoading: false,
    }
  },
  watch: {
    wordString(val) {
      this.currentPage = 1
      const arrData = this.newTable.filter(
        (e) => e.macid.includes(this.wordString) || e.boxMAC.includes(this.wordString)
      )
      this.total = arrData.length
    },
  },
  computed: {
    nobindtable() {
      return this.newTable
        .filter((e) => e.macid.includes(this.wordString) || e.boxMAC.includes(this.wordString))
        .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  mounted() {
    this.id = this.$route.query.pkid
    this.overviewInit()
  },
  methods: {
    formatter(row) {
      const obj = {
        VISIBLE_LIGHT: '可见光',
        INFRARED: '红外',
        UNKNOWN: '未知',
      }
      return obj[row.cameraType]
    },
    indexTable(index) {
      if (typeof index === 'undefined') {
        return 0
      }
      return index + 1
    },
    noindexTable(index) {
      if (typeof index === 'undefined') return
      return index + 1
    },
    nobindtableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return 'hideLabel'
      }
    },
    tableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'hideLabel'
      }
    },
    selectInit(row, index) {
      if (row.bindStatus) {
        return false //不可勾选
      } else {
        return true //可勾选
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    mysort(a, b) {
      //bindStatus 都为true
      if (a.bindStatus && b.bindStatus) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //bindStatus 都为false
      if (!a.bindStatus && !b.bindStatus) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //bindStatus 不同时为true
      else {
        return a.bindStatus > b.bindStatus ? 1 : -1
      }
    },
    getCameraList(msg) {
      this.bindLoading = true
      this.unbindLoading = true
      upgrade
        .getCameraList({
          pkid: this.id,
        })
        .then((res) => {
          console.log(res)
          if (res && res.status === 0) {
            this.cname = res.data.cname
            this.shopName = res.data.shopName
            this.boxPort = res.data.boxPort
            this.boxMacId = res.data.boxMacId
            this.agentno = res.data.agentno
            this.status = res.data.boxStatus
            this.bindTableData = res.data.hasbind
            this.newTable = res.data.nobind.sort(this.mysort)
            for (const v of this.newTable) {
              v.boxMAC = v.boxMAC || ''
            }
            this.pkid = res.data.pkid
            this.total = this.newTable.length
            if (!msg) return
            this.$message({
              message: '扫描成功',
              type: 'success',
            })
          }
        })
        .finally(() => {
          this.bindLoading = false
          this.unbindLoading = false
        })
    },
    bindCamera() {
      let data = {
        pkid: this.id,
        cameraid: this.multipleSelection.map((v) => v.macid).join(),
      }

      upgrade.bindCamera(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert(res.data.join(), '绑定提示', {
            confirmButtonText: '确定',
          })
          this.getCameraList()
        }
      })
    },
    rowSelection(rows) {
      rows.forEach((row) => {
        if (!row.bindStatus) {
          this.$refs.multipleTable.toggleRowSelection(row)
        }
      })
    },
    toggleSelection(rows) {
      //全选
      // if (this.multipleSelection.length) {
      //   this.$refs.multipleTable.clearSelection();
      //   this.select = false;
      // } else {
      //   rows.forEach(row => {
      //     if (!row.bindStatus) {
      //       this.$refs.multipleTable.toggleRowSelection(row);
      //     }
      //   });
      //   this.select = true;
      // }
      if (this.multipleSelection.length && this.isIndeterminate) {
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.rowSelection(rows)
          this.select = true
        }, 100)
      } else {
        this.rowSelection(rows)
        this.select = this.multipleSelection.length > 0
      }
    },
    handleSelectionChange(row) {
      //选中一条
      this.multipleSelection = row
      this.isIndeterminate = row.length > 0 && row.length < this.nobindtable.length
      if (
        this.multipleSelection.length &&
        this.multipleSelection.length === this.nobindtable.filter((e) => e.bindStatus === false).length
      ) {
        this.select = true
      } else {
        this.select = false
      }
    },
    bindBox() {
      if (!this.multipleSelection.length) {
        this.$alert('请先选择要绑定的摄像头', '绑定', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      if (this.bindTableData.length + this.multipleSelection.length > 1000) {
        this.$alert('摄像头绑定数量已达上限', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      this.$confirm('确定要绑定到该盒子上吗?', '绑定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.bindCamera()
      })
    },
    overviewInit() {
      //页面初始化
      this.getCameraList()
    },
    back() {
      this.$router.go(-1)
    },
    next() {
      this.$router.go(-1)
    },
    linklive(row) {
      this.$router.push({
        path: '/box/query/live',
        query: {
          cameraid: row.camerid,
        },
      })
    },
    change(row) {
      this.$router.push({
        path: '/box/query/change',
        query: {
          cameraId: row.camerid,
        },
      })
    },
    unbind(row) {
      console.log(row)
      this.$confirm(`是否解绑${row.camerid}摄像头`, '提示').then(() => {
        this.unbindCamera(row)
      })
    },
    unbindCamera(row) {
      let data = {
        pkid: this.id,
        cameraid: row.camerid,
      }
      upgrade.unbindCamera(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert(res.data.join(), '解绑提示', {
            confirmButtonText: '确定',
          })
          this.getCameraList()
        }
      })
    },
    handleClose(done) {
      done()
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
    // 跳转到设备信息页
    toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      const accessToken = localStorage.getItem('accessToken')
      window.open(
        href + '?accessToken=' + accessToken + '&cameraId=' + row.camerid + '&username=' + username + '&boxType=0'
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

* {
  box-sizing: border-box;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 0 20px 40px;
}
.content .detail {
  padding: 10px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
}
.content .detail .box {
  display: flex;
  align-items: center;
}
/* .content .detail div .label{
  float:left;
}
.content .detail  .box:nth-child(1) .text,
.content .detail  .box:nth-child(2) .text
{
  margin-left: 70px;
}
.content .detail  .box:nth-child(3) .text{
  margin-left: 56px;
}
.content .detail  .box:nth-child(4) .text{
  margin-left: 90px;
} */
.content .title {
  font-size: 18px;
  margin-top: 20px;
}

.content .title span {
  color: #5c76cc;
}

.bindtable,
.nobindtable,
.allSelect {
  margin-bottom: 20px;
}
.allSelect {
  display: flex;
  justify-content: space-between;
}
.allSelect .el-checkbox {
  margin-right: 20px;
}

.el-button {
  font-size: 12px;
}

.hideLabel .el-checkbox,
.hideLabel div {
  display: none !important;
}
.newDevice {
  margin-top: 20px;
}
.unbind::before {
  content: '';
  height: 5px;
  border-left: 1px solid @linkColor;
  margin-right: 12px;
}

.screenshot {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.imgDialog .el-dialog__header {
  padding: 0;
  border: none;
  height: 0px;
}

/* 关闭按钮 */
.imgDialog .el-dialog__headerbtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c2c2c2;
  right: 10px;
  top: 10px;
}

/* 关闭图标 */
.imgDialog .el-dialog__headerbtn .el-icon-close {
  color: #333;
}

.imgDialog .el-dialog__body {
  padding: 0;
}

.imgDialog .bigImgUrl {
  width: 740px;
  height: 600px;
  vertical-align: top;
}
.el-message-box__content {
  padding-left: 36px;
}

.word-string {
  width: 300px;
}
</style>